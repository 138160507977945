import '@/assets/css/styles.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createGtm } from '@gtm-support/vue-gtm';

import App from './App.vue';
import router from './libraries/router';
import loadConfig, { AppConfigInjectionKey } from './libraries/config';
import initLocalization from './libraries/i18n';
import initSentry from './libraries/sentry';
import initApi from './libraries/api';
import { VueQueryPlugin } from 'vue-query';
import {createMetaManager, defaultConfig, useMeta} from 'vue-meta';

import VSelect from 'vue-select';
import slider from 'vue3-slider';
import Slider from '@vueform/slider';
import initIframeHandler from '@/iframe/handler';

(async function () {
    const app = createApp(App);

    const config = await loadConfig();
    app.provide(AppConfigInjectionKey, config);

    const brandingState = config?.forced_settings?.branding || 'default';

    if (config.sentry.enabled) {
        initSentry(app, router, config.sentry.dsn);
    }

    initIframeHandler(config?.iframe?.enabled, config?.iframe?.target_url);

    initApi(config.api.url, config.forced_settings?.language);
    const i18n = await initLocalization(config.forced_settings?.language);

    app.use(i18n);
    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    staleTime: Infinity,
                    retry: 1,
                    refetchOnWindowFocus: false,
                },
            },
        },
    });
    app.use(createPinia());
    app.use(router);

    // This is needed to get the robots meta tag to show correctly
    // https://github.com/nuxt/vue-meta/issues/696
    const metaManager = createMetaManager(false, {
        ...defaultConfig,
        meta: {tag: 'meta', nameless: true},
    });

    metaManager.addMeta({
        meta: {
            name: "robots",
            content: config.robots ? 'INDEX,FOLLOW' : 'NOINDEX,NOFOLLOW',
        },
    });

    app.use(metaManager);

    if (config?.ga4?.enabled) {
        app.use(
            createGtm({
                id: config?.ga4?.id,
                defer: false,
                compatibility: false,
                loadScript: true,
                vueRouter: router,
                trackOnNextTick: false,
            })
        );
    }

    app.component('range-slider', Slider);
    app.component('v-select', VSelect);
    app.component('vue3-slider', slider);

    // Explicitly defined paths here, for vite can not handle dynamic imports otherwise
    const scssImports: Record<string, () => Promise<any>> = {
        default: () => import('@/assets/css/dynamic/default.scss'),
        autoselectr: () => import('@/assets/css/dynamic/autoselectr.scss'),
        produpress: () => import('@/assets/css/dynamic/produpress.scss'),
        produpress_fr: () => import('@/assets/css/dynamic/produpress_fr.scss'),
    };

    const loadBrandingStyles = async (branding: string) => {
        const scssImport = scssImports[branding] || scssImports.default;
        await scssImport().catch((err) => {
            console.error(
                `Failed to load SCSS file for branding ${branding}:`,
                err
            );
        });
    };

    loadBrandingStyles(brandingState)
        .then(() => {
            app.mount('#app');
        })
        .catch((err) => {
            console.error('Failed to mount', err);
        });
})();
